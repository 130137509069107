import React from "react";
import styled from "styled-components/macro";
import { Box, Heading, Text } from "bdd-ui-kit";
import colorType from "../../utils";

const Header = ({ isModalActive, setModalActive }) => {
  return (
    <Box
      css={`
        background-color: #000000;
        background-image: linear-gradient(315deg, #10161e 50%, #ffffff 74%);
        -webkit-box-shadow: 0 8px 6px -6px black;
        -moz-box-shadow: 0 8px 6px -6px black;
        box-shadow: 0 8px 6px -6px black;
        z-index: 5;
      `}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        px="30px"
        py="7px"
      >
        <Box width={{ _: "105px", lg: "300px" }}>
          <Box width={1}>
            <a href={"/"}>
              <img
                width="100%"
                height="100%"
                css={`
                  object-fit: cover;
                `}
                src={require("../../static/arigen_logo_tr.png")}
                alt="logo.png"
              />
            </a>
            <Box
              display={{ _: "none", lg: "block" }}
              ml={{ _: "24px", lg: "64px" }}
              textAlign="center"
              mt="-16px"
              css={`
                background: 50% 100% / 50% 50% no-repeat
                  radial-gradient(ellipse at bottom, black, black, black);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                animation: reveal 3000ms ease-in-out forwards 200ms,
                  glow 2500ms linear infinite 2000ms;

                @keyframes reveal {
                  80% {
                    letter-spacing: 8px;
                  }
                  100% {
                    background-size: 300% 300%;
                  }
                }
                @keyframes glow {
                  40% {
                    text-shadow: 0 0 8px black;
                  }
                }
              `}
            >
              <Text
                fontSize={{ _: "7px !important", lg: "14px !important" }}
                variant="subtitle"
              >
                <i>"geleceği tasarlar"</i>
              </Text>
            </Box>
            <Box
              display={{ _: "block", lg: "none" }}
              ml={{ _: "24px", lg: "64px" }}
              textAlign="center"
              mt="-16px"
            >
              <Text
                fontSize={{ _: "7px !important", lg: "14px !important" }}
                variant="subtitle"
              >
                <i>"geleceği tasarlar"</i>
              </Text>
            </Box>
          </Box>
        </Box>
        <Box display={{ _: "none", lg: "unset" }}>
          <Heading
            fontSize="24px !important"
            variant="h5"
            color={"#f3f3f3"}
            css={`
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            `}
            onClick={() => (window.location.href = "/")}
          >
            {"Anasayfa"}
          </Heading>
          <Heading
            ml="12px"
            fontSize="24px !important"
            variant="h5"
            color={"#f3f3f3"}
            css={`
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            `}
            onClick={() => (window.location.href = "#aboutUs")}
          >
            {"Hakkımızda"}
          </Heading>
          <Heading
            ml="12px"
            fontSize="24px !important"
            variant="h5"
            color={"#f3f3f3"}
            css={`
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            `}
            onClick={() => (window.location.href = "#ourWorks")}
          >
            {"Çözümlerimiz"}
          </Heading>
          <Heading
            ml="12px"
            fontSize="24px !important"
            variant="h5"
            color={"#f3f3f3"}
            css={`
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            `}
            onClick={() => (window.location.href = "#career")}
          >
            {"Kariyer"}
          </Heading>
          <Heading
            ml="12px"
            fontSize="24px !important"
            variant="h5"
            color={"#f3f3f3"}
            css={`
              &:hover {
                opacity: 0.8;
                cursor: pointer;
              }
            `}
            onClick={() => (window.location.href = "#contacts")}
          >
            {"İletişim"}
          </Heading>
        </Box>
        <Box
          display={{ _: "unset", lg: "none" }}
          onClick={() => setModalActive(!isModalActive)}
        >
          <Box
            bg={colorType.lightDark}
            width="15px"
            height="2px"
            borderRadius="5px"
          />
          <Box
            bg={colorType.lightDark}
            width="15px"
            height="2px"
            borderRadius="5px"
            my="2px"
          />
          <Box
            bg={colorType.lightDark}
            width="15px"
            height="2px"
            borderRadius="5px"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
