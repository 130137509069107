import React from "react";
import { Box, Text, Divider } from "bdd-ui-kit";
import styled from "styled-components/macro";
import colorType from "../../utils";

const Career = () => {
  return (
    <Box
      mx={{ _: "0px", lg: "auto" }}
      bg={colorType.darkBlue}
      py="80px"
      id="career"
    >
      <Box display="flex" flexDirection="column">
        <Text variant="title" color={colorType.white}>
          Kariyer
        </Text>
        <Divider
          variant="groove"
          width={{ _: "260px", lg: "400px" }}
          borderColor={colorType.white}
        />
        <Box px={{ _: "0px", lg: "400px" }} textAlign="center" mt="24px">
          <p
            css={`
              color: white;
            `}
          >
            Ekibimizde yer almak ve olası pozisyonlara yönelik sizin ile
            iletişime geçebilmemiz için detaylı ve özgün CV’lerinizi{" "}
            <b>
              <a
                css={`
                  text-decoration: none;
                  color: white;
                `}
                href="mailto:info@arigen.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                info@arigen.net
              </a>
            </b>{" "}
            eposta adresine <b>İş Başvurusu - CV</b> konusuyla
            gönderebilirsiniz.
          </p>
        </Box>
      </Box>
    </Box>
  );
};

export default Career;
