import React from "react";
import { Box, Card, Text, Divider } from "bdd-ui-kit";
import { colorType } from "../../../../utils/colors";
import styled from "styled-components/macro";

const WorkCard = ({ title, text }) => {
  return (
    <Box color="white" width={{ _: "100%", lg: "350px" }}>
      <Card
        bg={colorType.darkBlue}
        py="24px"
        px="16px"
        css={`
          &:hover {
            opacity: 0.8;
          }
        `}
      >
        <Box textAlign="center">
          <Text variant="body" fontSize="20px !important">
            {title}
          </Text>
        </Box>
        <Divider
          variant="groove"
          width="190px"
          borderColor={colorType.lightDark}
        />
        <Box textAlign="center">
          <Text variant="subtitle">{text}</Text>
        </Box>
      </Card>
    </Box>
  );
};

export default WorkCard;
