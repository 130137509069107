import React, { useState } from "react";
import Header from "../..//components/header";
import Hero from "../../components/hero";
import OurWorks from "../../components/ourWorks";
import AboutUs from "../../components/aboutUs";
import References from "../../components/references";
import Footer from "../../components/footer";
import Career from "../../components/career";
import { Modal, Box, Button, Heading } from "bdd-ui-kit";
import styled from "styled-components/macro";
import { colorType } from "../../utils/colors";

const App = () => {
  const [isModalActive, setModalActive] = useState(false);
  return (
    <React.Fragment>
      <Box position="relative" width="100%" height="100%">
        <Header isModalActive={isModalActive} setModalActive={setModalActive} />
        <Hero />
        <AboutUs />
        <OurWorks />
        <Career />
        <Footer />
        {isModalActive && (
          <Box
            css={`
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 2;
              width: 100%;
              height: 100%;
              overflow: hidden;
            `}
            backgroundColor={colorType.darkBlue2}
          >
            <Box
              px="16px"
              pt="32px"
              display="flex"
              flexDirection="column"
              alignItems="left"
              justifyContent="left"
              textAlign="left"
            >
              <Box>
                <Heading
                  fontSize="24px !important"
                  variant="h5"
                  color={"white"}
                  css={`
                    &:hover {
                      opacity: 0.8;
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    window.location.href = "/";
                    setModalActive(false);
                  }}
                >
                  {"Anasayfa"}
                </Heading>
              </Box>
              <Box mt="12px">
                <Heading
                  fontSize="24px !important"
                  variant="h5"
                  color={"#f3f3f3"}
                  css={`
                    &:hover {
                      opacity: 0.8;
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    window.location.href = "#aboutUs";
                    setModalActive(false);
                  }}
                >
                  {"Hakkımızda"}
                </Heading>
              </Box>
              <Box mt="12px">
                <Heading
                  fontSize="24px !important"
                  variant="h5"
                  color={"#f3f3f3"}
                  css={`
                    &:hover {
                      opacity: 0.8;
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    window.location.href = "#ourWorks";
                    setModalActive(false);
                  }}
                >
                  {"Çözümlerimiz"}
                </Heading>
              </Box>
              <Box mt="12px">
                <Heading
                  fontSize="24px !important"
                  variant="h5"
                  color={"#f3f3f3"}
                  css={`
                    &:hover {
                      opacity: 0.8;
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    window.location.href = "#career";
                    setModalActive(false);
                  }}
                >
                  {"Kariyer"}
                </Heading>
              </Box>
              <Box mt="12px">
                <Heading
                  fontSize="24px !important"
                  variant="h5"
                  color={"#f3f3f3"}
                  css={`
                    &:hover {
                      opacity: 0.8;
                      cursor: pointer;
                    }
                  `}
                  onClick={() => {
                    window.location.href = "#contacts";
                    setModalActive(false);
                  }}
                >
                  {"İletişim"}
                </Heading>
              </Box>
            </Box>
            <Button
              bg={colorType.darkBlue2}
              color="gray"
              size="lightGray"
              onClick={() => setModalActive(false)}
              position="absolute"
              top="2"
              right="2"
            >
              {"X"}
            </Button>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default App;
