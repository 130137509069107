import React from "react";
import { Box, Text, Divider } from "bdd-ui-kit";
import styled from "styled-components/macro";
import colorType from "../../utils";

const AboutUs = () => {
  return (
    <Box
      mx={{ _: "0px", lg: "auto" }}
      bg={colorType.darkBlue}
      py="50px"
      id="aboutUs"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Text variant="title" color={colorType.white}>
          Hakkımızda
        </Text>
        <Divider
          variant="groove"
          width={{ _: "260px", lg: "400px" }}
          borderColor={colorType.white}
        />
        <Box px={{ _: "0px", lg: "350px" }} textAlign="center" mt="24px">
          <Text color={colorType.white}>
            ARIGEN Yapay Zekâ ve Yeni Nesil Teknolojileri Anonim Şirketi 2020
            yılında Kayseri’de kurulmuştur. İşletmeci, iktisatçı, mühendis, diş
            hekimi, avukat, iş insanı meslek dallarında yatırımcı ve ortakları
            bulunan firmanın mühendislik ve sağlık kökenli uzman akademisyen
            kurulu da işletmenin ortakları arasında yer almaktadır. Arigen,
            yapay zekâ ile desteklenebilecek özellikle sağlık, savunma sanayi,
            enerji ve mühendislik gibi birçok farklı kulvarda faaliyet
            gösterebilecek dinamik bir organizasyon yapısına sahip olmakla
            birlikte, sahiplenilen ve sahiplenen genç yaştaki uzman personeli
            vasıtasıyla farklı alan ve disiplinlerdeki proje çalışmalarına hızla
            uyum sağlayabilme yetisine sahiptir.
            <br /> <br />
          </Text>
        </Box>
        <Box px={{ _: "0px", lg: "350px" }} textAlign="center">
          <Text variant="title" color={colorType.white}>
            Vizyon
          </Text>
          <Box mt="12px">
            <Text color={colorType.white}>
              Firmanın kuruluşunun esas gayesini oluşturan yüksek katma değerli
              milli teknolojiler geliştirmek, geliştirilen bu teknolojiler
              vasıtasıyla sadece yerel pazarda değil küresel pazarda da tanınan
              ve güven duyulan bir teknoloji firması olmak hedefi doğrultusunda,
              paydaşlarıyla karşılıklı değerler oluşturmak, sürdürülebilir
              büyümeyi sağlamak, tercih edilen, çevreye ve insana duyarlı doğru
              bir kuruluş olmak.
              <br /> <br />
            </Text>
          </Box>
        </Box>
        <Box px={{ _: "0px", lg: "350px" }} textAlign="center">
          <Text variant="title" color={colorType.white}>
            Misyon
          </Text>
          <Box mt="12px">
            <Text color={colorType.white}>
              Yapay zekâ teknolojileri, büyük veri ve ilgili alanlarda; sağlık,
              enerji ve savunma sanayii başta olmak üzere gerek yurt içindeki
              gerekse de yurt dışındaki müşterilerine katma değeri yüksek,
              yenilikçi ve güvenilir çözümler sunmak; böylelikle Ülkemizin
              teknolojik dışa bağımlılığını azaltarak, global ölçekte marka
              bilinirliğini sağlamış, ülkesine gurur veren bir teknoloji şirketi
              olmak.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
