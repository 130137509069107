import React from "react";
import { Box, Text, Divider } from "bdd-ui-kit";
import styled from "styled-components/macro";
import colorType from "../../utils";
import GoogleMapReact from "google-map-react";

const Footer = () => {
  const AnyReactComponent = ({ text }) => (
    <Box
      css={`
        width: 10px;
        height: 10px;
        background: linear-gradient(#ddd, #fff);
        border: 10px solid #e04246;
        border-radius: 80px 80px 2px;
        box-shadow: 3px 3px 20px #999;
      `}
    >
      <Box width={1}>
        <p
          css={`
            font-weight: 800;
          `}
        >
          Arigen
        </p>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        bg={colorType.darkBlue2}
        py="50px"
        px={{ _: "0px", lg: "340px" }}
        id="contacts"
        width={1}
      >
        <Box textAlign="center">
          <Text variant="title" color={colorType.white}>
            İletişim
          </Text>
          <Divider
            variant="groove"
            width={{ _: "260px", lg: "400px" }}
            borderColor={colorType.white}
          />
        </Box>
        <Box
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
          alignItems="center"
          justifyContent="space-between"
          mt="48px"
          width={1}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="left"
            justifyContent="center"
            width={1}
          >
            <Box>
              <Text variant="body" color={colorType.white}>
                ARIGEN Yapay Zeka ve Yeni Nesil Teknolojileri AS <br /> Yıldırım
                Beyazıt Mh. Aşık Veysel Blv. <br /> ERÜ Teknoloji Geliştirme
                İdare ve Kuluçka Merkezi <br /> Tekno-1 Binası No:61/37
                Melikgazi/Kayseri
                <br />0 (352) 504 70 38
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              width={1}
              mt="1em"
            >
              <a href="https://twitter.com/ARIGEN_AI_TECHS">
                <Box borderRadius="25" width="50px" mr="25px">
                  <img
                    width="100%"
                    height="100%"
                    css={`
                      object-fit: cover;
                    `}
                    src={require("../../static/twitter_logo.png")}
                    alt="logo.png"
                  />
                </Box>
              </a>
              <a
                href="mailto:info@arigen.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box borderRadius="25" width="50px" mr="25px">
                  <img
                    width="100%"
                    height="100%"
                    css={`
                      object-fit: cover;
                    `}
                    src={require("../../static/gmail_logo.png")}
                    alt="logo.png"
                  />
                </Box>
              </a>
              <a href="https://www.linkedin.com/company/arigen-ai-technologies">
                <Box borderRadius="25" width="50px" mr="15px">
                  <img
                    width="100%"
                    height="100%"
                    css={`
                      object-fit: cover;
                    `}
                    src={require("../../static/linkedln_logo.png")}
                    alt="logo.png"
                  />
                </Box>
              </a>
              <a href="https://www.instagram.com/arigen.ai.techs/">
                <Box borderRadius="25" width="65px" mr="25px">
                  <img
                    width="100%"
                    height="100%"
                    css={`
                      object-fit: cover;
                    `}
                    src={require("../../static/instagram_logo.png")}
                    alt="logo.png"
                  />
                </Box>
              </a>
            </Box>
          </Box>{" "}
          <Box width={1} ml={{ _: "1em", lg: "0px" }}>
            <Box
              position="relative"
              maxWidth={{ _: "280px", lg: "380px" }}
              style={{ height: "210px", width: "100%" }}
            >
              <a
                href={
                  "https://www.google.com/maps/place/38%C2%B042'56.2%22N+35%C2%B031'51.4%22E/@38.7156077,35.5300666,18z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d38.7156064!4d35.5309415"
                }
                target="_blank"
                rel="noopener noreferrer"
                css={`
                  text-decoration: none;
                  color: black;
                `}
              >
                <Box
                  zIndex="999"
                  position="absolute"
                  top="10"
                  left="20"
                  px="12px"
                  py="6px"
                  bg="white"
                  borderRadius="8px"
                  ml="5px"
                  mt="5px"
                  css={`
                    box-shadow: 3px 3px 20px #999;
                  `}
                >
                  <Text>Yol Tarifi</Text>
                </Box>
              </a>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDXTFisVTSjS86vo1-a4CBgdkXGaedDdeg",
                }}
                defaultCenter={{ lat: 38.71556, lng: 35.530965 }}
                defaultZoom={16}
              >
                <AnyReactComponent lat={38.71556} lng={35.530965} />
              </GoogleMapReact>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bg={colorType.darkBlue2}
        display="flex"
        justifyContent="center"
        pb="5px"
      >
        <Text color={colorType.white}>Copyright - 2020</Text>
      </Box>
    </>
  );
};

export default Footer;
