import React from "react";
import styled from "styled-components/macro";
import { Box, Text } from "bdd-ui-kit";
import colorType from "../../utils";
import background from "../../static/hero_image.jpg";

const Hero = () => {
  return (
    <Box width={1} id="hero">
      <Box
        width={1}
        minHeight="620px"
        css={`
          background-image: url(${background});
          background-repeat: no-repeat, repeat;
          background-size: cover;
          box-shadow: inset 0 0 0 2000px rgba(19, 23, 44, 0.5);
        `}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxWidth="450px">
          <Box display="flex" flexDirection="column" textAlign="center">
            <Box>
              <Text
                color={colorType.white}
                variant="title"
                fontSize="54px !important"
                letterSpacing="4px !important"
                lineHeight="more"
              >
                {"ARIGEN"}
              </Text>
            </Box>
            <Box>
              <Text color={colorType.white} variant="body">
                {
                  "Sağlık, savunma sanayii, enerji verimliliği, akıllı şehircilik ve  endüstriyel üretim başta olmak üzere daha birçok alanda ihtiyaç duyduğunuz yapay zeka temelli çözümleri size doğru ve güvenilir bir biçimde sağlayacak çözüm ortağınız."
                }
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
