module.exports = {
  colorType: {
    lightDark: "#595a5c",
    dark: "#333232",
    primary: "#50c8e7",
    white: "#fff",
    lightBlue: "#1B1D38",
    darkBlue: "#10161E",
    darkBlue2: "#313131",
    darkBlue21: "#010330",
    darkBlue22: "#13162D",
    darkGray: "#272727",
    darkGray2: "#4F4F4F",
  },
};
