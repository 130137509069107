import React from "react";
import styled from "styled-components/macro";
import { Box, Text, Divider } from "bdd-ui-kit";
import colorType from "../../utils";
import WorkCard from "./subComponents/workCard/";

const OurWorks = () => {
  return (
    <Box bg={colorType.darkBlue2} py="50px" id="ourWorks">
      <Box display="flex" flexDirection="column">
        <Box textAlign="center">
          <Text variant="title" color={colorType.white}>
            Çözümlerimiz - Yapay Zeka Odaklı Faaliyet Alanlarımız
          </Text>
        </Box>
        <Divider
          mt="5px"
          mb="25px"
          variant="groove"
          width={{ _: "120px", lg: "400px" }}
          borderColor={colorType.white}
        />
        <Box
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
          justifyContent="space-between"
          mx={{ _: "0px", lg: "100px" }}
          mt="24px"
        >
          <WorkCard title={"Sağlık"} />
          <WorkCard title={"Savunma Sanayii"} />
          <WorkCard title={"Büyük Veri"} />
        </Box>
        <Box
          mt="48px"
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
          justifyContent="space-between"
          mx={{ _: "0px", lg: "100px" }}
        >
          <WorkCard title={"Enerji"} />
          <WorkCard title={"İş Zekası"} />
          <WorkCard title={"Akıllı Şehirler"} />
        </Box>
        <Box
          mt="48px"
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
          justifyContent="space-between"
          mx={{ _: "0px", lg: "100px" }}
        >
          <WorkCard title={"Finans"} />
          <WorkCard title={"Siber Güvenlik"} />
          <WorkCard title={"Akıllı İmalat"} />
        </Box>
        <Box
          mt="48px"
          display="flex"
          flexDirection={{ _: "column", lg: "row" }}
          justifyContent="space-between"
          mx={{ _: "0px", lg: "100px" }}
        >
          <WorkCard title={"Biyoinformatik"} />
          <WorkCard title={"GPU Tabanlı Hesaplama"} />
        </Box>
      </Box>
    </Box>
  );
};

export default OurWorks;
